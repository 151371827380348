import React from 'react'
import "./Portfolio.css"
import {Swiper, SwiperSlide} from 'swiper/react'
import Sidebar from "../../img/sidebar.png";
import Ecommerce from "../../img/ecommerce.png";
import DK from "../../img/dk.png";
import IN from "../../img/in.png";
import IS from "../../img/is.png";
import UI from "../../img/ui.png";
import UI2 from "../../img/ui2.png";
import HOC from "../../img/hoc.png";
import MusicApp from "../../img/musicapp.png";
import 'swiper/css'
import { themeContext } from "../../Context";
import { useContext } from "react";


const Portfolio = () => {
    const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className='portfolio' id='Portfolio'>
    
    {/* heading */}
    <span style={{color: darkMode? 'white' : ''}}> Recent Projects</span>
    <span> Portfolio </span>
    
    {/* slider */} 
    <Swiper
    spaceBetween={30}
    slidesPerView={3}
    grabCursor={true}
    className='portfolio-slider'
    >
      <SwiperSlide>
        <img src={DK} alt='' />
      </SwiperSlide>
      <SwiperSlide>
        <img src={IN} alt='' />
      </SwiperSlide>
      <SwiperSlide>
        <img src={IS} alt='' />
      </SwiperSlide>
      <SwiperSlide>
        <img src={UI} alt='' />
      </SwiperSlide>
      <SwiperSlide>
        <img src={UI2} alt='' />
      </SwiperSlide>
    </Swiper>
    
    </div>
  )
}

export default Portfolio