import React from 'react'
import './Skills.css'
import HeartEmoji from "../../img/heartemoji.png";
import Glasses from "../../img/glasses.png";
import Humble from "../../img/humble.png";
import Card from '../Card/Card';
import Resume from './Resume.pdf';
import { themeContext } from "../../Context";
import { useContext } from "react";
import {motion} from 'framer-motion'


const Skills = () => {

    //for motion
  const transition = {duration:2, type: 'spring'}

  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className='skills' id='Skills'>

    {/* left side */}
    <div className='awesome'>
        <span style={{color: darkMode? 'white' : ''}}>My Awesome</span>
        <span>Technical Skills</span>
         <span><b>Languages: </b>HTML/CSS, SCSS, Java, JavaScript/Typescript, Swift, C#, PHP, Bootstrap, EJS<br/>
<b>Frameworks:</b> NodeJS, ExpressJS, React, REST APIs, AEM, jQuery, Mongoose,JSON, Microsoft Office<br/> (Word, Excel,
PowerPoint, Project)<br/>
<b>Database:</b> MySQL, Mongo DB, MongoDB, WooCommerce, CRXDE Lite, NPM, WebPack, CPanel, DirectAdmin<br/>
<b>CMS:</b> AEM, WordPress, Wix, Square Space, Shopify<br/>
<b>Designing:</b> Canva, Adobe XD, Photoshop, After Effects, Lottie Files, and Illustrator<br/>
<b>Collaboration:</b> Git, GitHub, Microsoft Teams, Zoom, Outlook, Jira, Replit<br/>
<b>Other:</b> Heroku, Google Firebase, VSCode, MongoDB Atlas, SEO, REST API, Yarn, Maven, Azure,<br/> Google Ads, Webpack</span>
     
      <a href={Resume} download>
      <button className='button s-button'>Download CV</button>
      </a>
       
        <div className='blur s-blurl' style={{background: "#ABF1FF94"}}></div>
    </div>

     {/* first card right side f */}
    <div className='cards'>
      <motion.div
      initial={{left: '25rem'}}
      whileInView={{left: '14rem'}}
      transition={transition} style={{left: '21rem'}}>

        <Card
          emoji = {HeartEmoji}
          heading = {'Designing'}
          detail = {"Canva, Adobe XD, Photoshop, After Effects, Lottie Files, and Illustrator"}
        />
      </motion.div>
    

{/* second card */}
    <motion.div
      initial={{left: '-20rem'}}
      whileInView={{left: '-1rem'}}
      transition={transition} style={{top: "12rem", left:"50%"}}>
      <Card
          emoji = {Glasses}
          heading = {'CMS'}
          detail = {"AEM, WordPress, Wix, Square Space, Shopify"}
        />
    </motion.div>

{/* third card */}
    <motion.div
      initial={{left: '25rem'}}
      whileInView={{left: '18rem'}}
      transition={transition} style={{top: "19rem", left:"17rem"}}>
      <Card
          emoji = {Humble}
          heading = {'Frameworks'}
          detail = {"NodeJS, ExpressJS, React, REST APIs, AEM, jQuery, Mongoose,JSON"}
        />
    </motion.div>
    <div className='blur s-blur2' style={{ background: "var(--purple)"}}></div>

</div>
    </div>
  )
}

export default Skills