import React from 'react'
import './Intro.css'
import Github from '../../img/github.png';
import LinkedIn from '../../img/linkedin.png';
import Instagram from '../../img/instagram.png';
import lottiefiles from '../../img/lottiefiles.png';
import Vector1 from '../../img/Vector1.png'; 
import Vector2 from '../../img/Vector2.png'; 
import satyam from '../../img/satyam.png'; 
import thumbup from '../../img/thumbup.png'; 
import Crown from '../../img/crown.png'; 
import glassesimoji from '../../img/glassesimoji.png'; 
import FloatingDiv from '../FloatingDiv/FloatingDiv';
import { themeContext } from "../../Context";
import { useContext } from "react";
import {motion} from 'framer-motion'

const Intro = () => {

  //for motion
  const transition = {duration:2, type: 'spring'}


  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    
    <div className='intro'>
        <div className='i-left'>
        <div className='i-name'>
            <span style={{color: darkMode? 'white' : ''}}>Hy! I am </span>
            <span>Satyam Gajjar </span>
            <span>Innovative frontend developer with a keen eye for design and a passion for creating dynamic user experiences. Looking forward
            to obtain career in an industry, where I can utilize my knowledge and skill set to make use of all resources to maximum extent and achieve the 
            greatest height in respective area.

            </span>
        </div>
         <button className='button i-button'>Call</button>
  

    {/* icons */}
    <div className='i-icons'>
       <a href='https://github.com/satyamgajjar21' target="_blank"><img src={Github} alt="" /></a>
       <a href='https://www.linkedin.com/in/satyam-gajjar-b10193190/' target="_blank"><img src={LinkedIn} alt="" /> </a>
       <a href='https://www.instagram.com/sat_21_/' target="_blank"><img src={Instagram} alt="" /> </a>
    </div>
  </div>

    <div className='i-right'>
      <img src={Vector1} alt="v1"/>
      <img src={Vector2} alt="v2"/>
      <img src={satyam} alt="boy"/>
      <motion.img
      initial={{left: '-36%'}}
      whileInView={{left: '-24%'}}
      transition={transition}

      src={glassesimoji} alt="emoji"/>
      {/* Image up icon */}
    
    <motion.div
      initial={{top: '-4%',left: '-74%'}}
      whileInView={{left: '68%'}}
      transition={transition}

     style={{top: '-4%', left: '68%'}}
     className='floating-div'>
      <FloatingDiv image={Crown} txt2='Developer'/>
    </motion.div>

    <motion.div
      initial={{top: '18rem',left: '9rem'}}
      whileInView={{left: '0rem'}}
      transition={transition}
      
      style={{top: '18rem', left: '0rem'}} className='floating-div'>
      <FloatingDiv image={thumbup} txt1='Wide Technical' txt2='Skills'/>
    </motion.div>

    {/* Blur Divs */}
    <div className='blur' style={{background: "rgb(238 210 255)"}}></div>
    <div className='blur' style={{background: '#C1F5FF',
    top: '17rem',
    width: '21rem',
    height: '11rem',
    left: '-9rem',

    }}></div>

    </div>
    </div>
  )
}

export default Intro