import React from 'react';
import './Footer.css';

import Insta from '@iconscout/react-unicons/icons/uil-instagram' 

import Facebook from '@iconscout/react-unicons/icons/uil-facebook'
import Github from '@iconscout/react-unicons/icons/uil-github'
import Wave from '../../img/wave.png'

const Footer = () => {
  return (
    <div className="footer">
        <img src={Wave} alt='' style={{width: '100%'}}/>
        
        <div className="f-content">
            <span>satyamgajjar21@gmail.com</span>
            <div className="f-icons">
            <a href='https://www.instagram.com/sat_21_/' target="_blank"><Insta color="white" size='3rem'/></a>
 
                <a href='https://www.facebook.com/satyam.gajjar.52' target="_blank"><Facebook color="white" size='3rem'/></a>
               <a href='https://github.com/satyamgajjar21' target="_blank"><Github color="white" size='3rem'/></a> 
            </div>
            <div>
              <span>Copyrights @ satyam gajjar</span>
            </div>
        </div>
    </div>
  )
}

export default Footer