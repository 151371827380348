import React from 'react'
import './Education.css'
import {Swiper, SwiperSlide} from 'swiper/react';
import ProfilePic1 from "../../img/profile1.jpg";
import ProfilePic2 from "../../img/profile2.jpg";
import ProfilePic3 from "../../img/profile3.jpg";
import ProfilePic4 from "../../img/profile4.jpg";
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';

const Education = () => {
      const clients = [
        {
          // img: ProfilePic1,
          review: "MOBILE SOLUTIONS DEVELOPMENT,CONESTOGA COLLEGE - KITCHENER ON, CANADA."
        },
        {
          // img: ProfilePic2,
          review: "BACHELOR OF COMPUTER ENGINEERING, BITS EDU CAMPUS - VADODARA, GUJARAT, INDIA"
        }
         
      ]



  return (
    <div className='t-wrapper'>
        <div className='t-heading'   id='Education'>
            <span>Relevant</span>
            <span> Education </span>
            <span>Experiences</span>
            <div className='blur t-blurl' style={{background: "var(--purple)"}}></div>
            <div className='blur t-blur2' style={{background: "skyblue"}}></div>
        </div>
{/* slider */}
{/* fetching data from the clients array */}
<Swiper
modules={[Pagination]}
slidesPerView={1}
pagination={{clickable: true}}
>
  {clients.map((client, index) => {
    return (
      <SwiperSlide key={index}>
      <div className="education">
        {/* <img src={client.img} alt='' /> */}
        <span>{client.review}</span>
      </div>
      </SwiperSlide>
    );
  })}
</Swiper>

    </div>
  );
};

export default Education