import React from 'react'
import './Works.css'
import Upwork from "../../img/Upwork.png";
import JavaScript from "../../img/js1.png";
import AEM from "../../img/aem1.png";
import BlackBerry from "../../img/bb.png";
import Shopify from "../../img/Shopify.png";
import njs from "../../img/njs.png";
import { themeContext } from "../../Context";
import { useContext } from "react";
import {motion} from 'framer-motion';
import Resume from './Resume.pdf';


const Works = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className='skills'>
         {/* left side */}
    <div className='awesome'>
        <span style={{color: darkMode? 'white' : ''}}>Recent Experience</span>
        <span>BlackBerry</span>
         <span>-Worked on Adobe Experience Manager, HTML ,JavaScript, SCSS and Visual Studio Code.<br/>
-Used AEM to create and update web pages and handled daily technical support activities<br/>on web pages support, pages
management.<br/>
-Updated and uploaded assets such as video, PDF and images on DAM. Later used them on websites.<br/>
-Searched for broken images and updated them.<br/>
-Used CRXDE to update the Resource section of BlackBerry.<br/>
-Used Jira to log, updates tickets and replied to stakeholders. <br/>
-Wrote a technical documentation and manuals, later maintained throughout for future students <br/> as reference and used sheets to
maintain all the tickets.<br/>
-Interacted with staff on desktop problems and their resolution.<br/>
-Recorded technical issues and solutions in logs and jira.<br/>
-Collaborated with developers, colleague and other team members to integrate <br/>and develop components in an agile
environment.<br/>
-Worked in Agile development environment and attended SCRUM meetings daily.<br/>
-Installed and worked on NPM & Webpack packages.
         </span>
      
       <a href={Resume} download>
      <button className='button s-button'>Download CV</button>
      </a>
         <div className='blur s-blurl' style={{background: "#ABF1FF94"}}></div>
    </div>


{/* right side */}
<div className='w-right'>
    <motion.div 
    initial={{ rotate: 45}}
    whileInView={{rotate: 0}}
    viewport={{margin: '-40px'}}
    transition={{duration: 3.5, type: 'spring'}}
    className='w-mainCircle'>
    <div className='w-secCircle'>
        <img src={BlackBerry} alt='' />
    </div>
    <div className='w-secCircle'>
        <img src={JavaScript} alt='' />
    </div>
    <div className='w-secCircle'>
        <img src={AEM} alt='' />
    </div>
    <div className='w-secCircle'>
        <img src={Shopify} alt='' />
    </div>
    <div className='w-secCircle'>
        <img src={njs} alt='' />
    </div>
    </motion.div>

    {/* background circle */}
    <div className='w-backCircle blueCircle'></div>
    <div className='w-backCircle yellowCircle'></div>
    </div>
    </div>
  )
}

export default Works