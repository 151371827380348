import React from 'react'
import './Experience.css'


const Experience = () => {
  return (
    <div className='experience' id="Experience">
    <div className='achievement'>
   <div className='circle'>1+</div>
   <span>years</span>
   <span>Experience</span>
    </div>
    <div className='achievement'>
   <div className='circle'>10+</div>
   <span>completed</span>
   <span>Projects</span>
    </div>
    <div className='achievement'>
   <div className='circle'>7+</div>
   <span>Known</span>
 <span>technologies</span>
    </div>
    </div>
  )
}

export default Experience